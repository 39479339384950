import { Username } from './ubiquitousTypes'

export enum SystemAccessActionsEnum {
  allow = 'allow',
  deny = 'deny',
}
export enum PermissionOperation {
  All = 'All',
  View = 'View',
}
export type RoleName = string
export type ResourceName = string
export type ResourceId = string

export enum AccountStatusEnum {
  active = 'active',
  arrears = 'arrears',
  deactivated = 'deactivated',
}
export type StopGapResourceBasedPolicy = Record<
  SystemAccessActionsEnum,
  { idPassport: Username; access: { expiryDate: string; type: AccountStatusEnum } }[]
>

export enum SystemFeaturesEnum {
  all = 'all',
  advancedDataQueries = 'advancedDataQueries',
  attendance = 'attendance',
  billing = 'billing',
  cohorts = 'cohorts',
  documents = 'documents',
  documentBuilder = 'documentBuilder',
  deviceManagement = 'deviceManagement',
  discipline = 'discipline',
  exports = 'exports',
  employment = 'employment',
  imports = 'imports',
  jobEligibility = 'jobEligibility',
  groups = 'groups',
  performance = 'performance',
  ppe = 'ppe',
  users = 'users',
  reporting = 'reporting',
  requests = 'requests',
  systemConfiguration = 'systemConfiguration',
  termination = 'termination',
  workforce = 'workforce',
}

export enum SystemOperationsEnum {
  all = 'all',

  // advancedDataQueries
  viewDataQueries = 'viewDataQueries',
  editQueries = 'editQueries',

  // billing
  viewBillingDashboard = 'viewBillingDashboard',

  //cohorts
  editProfileCohorts = 'editProfileCohorts',
  listEmployeesInCohort = 'listEmployeesInCohort',
  listCandidatesInCohort = 'listCandidatesInCohort',
  listTrashInCohort = 'listTrashInCohort',

  // deviceManagement
  viewDeviceLogs = 'viewDeviceLogs',

  // discipline
  editDiscipline = 'editDiscipline',
  viewDiscipline = 'viewDiscipline',
  editDisciplineBulk = 'editDisciplineBulk',

  // documents
  editDocCheckedStatus = 'editDocCheckedStatus',
  deleteProfileDocumets = 'deleteProfileDocumets',
  downloadProfileDocuments = 'downloadProfileDocuments',
  downloadBulkProfileDocuments = 'downloadBulkProfileDocuments',
  generateProfileDocuments = 'generateProfileDocuments',
  viewProfileDocuments = 'viewProfileDocuments',
  editProfileDocuments = 'editProfileDocuments',

  //documentConfig
  viewDocumentLibrary = 'viewDocumentLibrary',
  editDocumentLibrary = 'editDocumentLibrary',

  //employment
  editEmployment = 'editEmployment',

  //jobEligibility
  viewJobEligibility = 'viewJobEligibility',

  // performance
  editPerformance = 'editPerformance',
  viewPerformance = 'viewPerformance',

  // ppe - Certain operations within PPE require access to the workforce feature as well. Ensure that roles are configured correctly.
  closePpeIssueRequest = 'closePpeIssueRequest',
  createPpeIssueRequest = 'createPpeIssueRequest',
  editPpeStock = 'editPpeStock',
  editPpeStockConfig = 'editPpeStockConfig',
  issuePpe = 'issuePpe',
  returnPpe = 'returnPpe',
  viewPpe = 'viewPpe',
  locationTransfer = 'locationTransfer',

  // reporting
  viewDocumentValidity = 'viewDocumentValidity',

  // systemConfiguration
  editDataFlowConfig = 'editDataFlowConfig',
  editFieldConfig = 'editFieldConfig',
  editProcessConfig = 'editProcessConfig',
  editJsonConfigFiles = 'editJsonConfigFiles',

  // termination
  editTermination = 'editTermination',
  editTerminationBulk = 'editTerminationBulk',

  // workforce
  viewCandidates = 'viewCandidates',
  editCandidates = 'editCandidates',
  viewEmployees = 'viewEmployees',
  editEmployees = 'editEmployees',
  viewFaceshots = 'viewFaceshots',
  viewProfileLogs = 'viewProfileLogs',
  // employ = "employ",
  // promote = "promote",
  // transfer = "transfer",
  // viewEmploymentPortfolio = "viewEmploymentPortfolio",
}

export enum StaticSystemResourcesEnum {
  TABNAVIGATOR_DOCS_RECRUITMENT_AND_ONBOARDING = 'RECRUITMENT_&_ONBOARDING',
  TABNAVIGATOR_DOCS_DISCIPLINE = 'DISCIPLINE',
  TABNAVIGATOR_DOCS_PERFORMANCE_MANAGEMENT = 'PERFORMANCE_MANAGEMENT',
  TABNAVIGATOR_DOCS_PAYROLL_LEAVE_HEALTH_SAFETY = 'PAYROLL,_LEAVE,_HEALTH_&_SAFETY',
  TABNAVIGATOR_DOCS_TERMINATION = 'TERMINATION',
  TABNAVIGATOR_DOCS_OTHER = 'TABNAVIGATOR_DOCS_OTHER',
}

export enum SystemResourcePrefix {
  docViewTab = 'docViewTab',
}
